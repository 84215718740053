// eslint-disable-next-line no-redeclare,no-unused-vars
function addIcon(cont, val, def, className) {
  if (!val) val = def;
  if (strpos(val, ',') > 0) val = val.split(',');

  var element;
  if (isArray(val)) {
    element = $('<span class="icon fa-stack"></span>');
    element.append(
      '<i class="icon fa-regular fa-' + val[0] + ' fa-stack-1x fa-2x"></i>'
    );
    element.append(
      '<i class="icon fa-regular fa-' + val[1] + ' fa-stack-1x fa-xs"></i>'
    );
  } else if (strpos(val, ' ') > 0) element = $('<i class="' + val + '"></i>');
  else element = $('<i class="icon fa-regular fa-' + val + '"></i>');

  if (className) element.addClass(className);
  if (cont) element.appendTo(cont);
  return element;
}
